import React from 'react';

const NotFoundPage = () => (
  <div style={{padding: "20px", textAlign: "center", color: "white"}}>
		<h1 style={{marginBottom: "20px"}}>404: Not Found</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
